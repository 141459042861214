import React, { useEffect, useState } from 'react';

import { storage } from './helpers/storage'
import { toLocalDate } from './helpers/date';

import './style.scss';

export interface ICurrencyData {
    Cur_Abbreviation: string;
    Cur_ID: number;
    Cur_Name: string;
    Cur_OfficialRate: number;
    Cur_Scale: number;
    Date: string;
}

type TCurrencyName = 'RUB' | 'USD' | 'EUR' | 'UAH' | 'PLN'

interface ICurrencyProps {
    currencyNames: TCurrencyName[]
}

interface IMapCurrName {
    [key: string]: number
}

interface IDefaultCurrency {
    [key: string]: number
}

interface IStats {
    [key: string]: number
}

function Currency(props: ICurrencyProps) {

    const [defaultCurrency, setDefaultCurrency] = useState<IDefaultCurrency>({})

    const { currencyNames } = props

    useEffect(() => {
        const stats = storage.get('stats') || {};
        const today: Date = new Date()
        const defaultCurrency: IDefaultCurrency = {};
        if (today.getHours() < 17) {
            const allCurrenciesFetch = currencyNames.map((curr: TCurrencyName, index: number) => {
                return fetch(`https://www.nbrb.by/API/ExRates/Rates/${curr}?ParamMode=2`)
                       .then(data => data.json())
            })
            setDefaultCurrency(defaultCurrency)
            Promise.all(allCurrenciesFetch)
                .then((data) => {
                    const statsToSave: IStats = {};
                    currencyNames.forEach((curr: TCurrencyName, index: number) => {
                        statsToSave[curr.toLowerCase()] = Number(data[index].Cur_OfficialRate.toFixed(3))
                    })
                    stats[toLocalDate(data[0].Date)] = statsToSave;
                    storage.set('stats', stats)
                })
        }
    }, [])

    const localeTomorrowDate = (): string => {
        const ddate = new Date();
        ddate.setDate(ddate.getDate() - 1)
        return toLocalDate(ddate);
    }

    const currentDayCurrency = (storage.get('stats') || {})[toLocalDate(new Date())] || defaultCurrency;

    const getDayGraph = (name: string) => {
        const tommorowCurrency = (storage.get('stats') || {})[localeTomorrowDate()] || defaultCurrency;

        const getDeltaCurrency = () => {
            return Math.abs(currentDayCurrency[name] - tommorowCurrency[name]).toFixed(3)
        };

        if (currentDayCurrency[name] > tommorowCurrency[name]) {
            return <span className="dayGraph up">↑<span className="delta">{getDeltaCurrency()}</span></span>
        } else if (currentDayCurrency[name]  < tommorowCurrency[name]) {
            return <span className="dayGraph down">↓<span className="delta">{getDeltaCurrency()}</span></span>
        } else {
            return <span className="dayGraph opacity"/>
        }
    }

    return (
    <div className='currencyWrapper'>
        {currencyNames.map((curr) => {
            return (
                <div className="currency" key={curr}>
                    <span className="name">{curr}</span>
                    <span className="value">{currentDayCurrency[curr.toLowerCase()]}<span className='nationalName'>BYN</span></span>
                    {getDayGraph(curr.toLocaleLowerCase())}
                </div>
            )
        })}
    </div>
  );
}

export default Currency;
