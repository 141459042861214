import React, { useCallback } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import Clock from '../../components/clock';

import particle_settings from '../../constants/particlesjs-config.json';

import './style.scss';

function Main() {
    console.log(particle_settings)
    const particlesInit = useCallback(async (engine: any) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);


    return (
    <div className="App">
      <Clock />
        <Particles
            id="tsparticles"
            init={particlesInit}
            //@ts-ignore
            options={particle_settings}
        />
    </div>
  );
}

export default Main;
