import React, { useState, useEffect } from 'react';

import DottedScreen from '../../components/dottedScreen';

import './style.scss';

const NewScreen = () => {

    const [counter, setCounter] = useState<number>(0)

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCounter((prevCounter) => prevCounter + 1);
    //     }, 1000);
    //
    //     return () => clearInterval(interval);
    // }, []);

    return (
        <DottedScreen>
            <div className="mainScreen">
                <h1>Count: {counter}</h1>
                {/*<h1>Привет Игорь!!!</h1>*/}
            </div>
        </DottedScreen>
  );
}

export default NewScreen;
