import React, { useState, useEffect, useRef }  from 'react';

import './style.scss';

interface ISwitcher {
    children?: React.ReactNode[] | React.ReactNode;
    showTime?: number;
}

function Switcher(props: ISwitcher) {
    const { children, showTime = 10 } = props;


    const [currentEl, setCurrentEl] = useState(0);

    useEffect(() => {
        let interval: any = null;
        if (Array.isArray(children)) {
            interval = setInterval(() => {
                setCurrentEl((prevEl) => {
                    const nextEl = prevEl + 1
                    return nextEl > children.length - 1 ? 0 : (prevEl + 1)
                })
            }, showTime * 1000)
        }

        return () => clearInterval(interval);
    }, [])

    return (
    <div className='switcherWrapper'>
        {(children && Array.isArray(children)) ? children.map((child, index) => {
            return (
                <div key={`child-${index}`} className={`switch ${index === currentEl ? 'visible' : 'hide'}`}>
                    {child}
                </div>
            )
        }) : children}
    </div>
  );
}

export default Switcher;
