import React, { useEffect, useState, useRef, useMemo } from 'react';
import html2canvas from 'html2canvas';

import './style.scss';

interface ITargetView {
    width: number;
    height: number;
}

const DOT_COLOR = 'blue'

const DottedScreen = (props: any) => {
    const {
        children,
        dotSize = 6,
        dotMargin = 4
    } = props;

    const viewRef = useRef<HTMLDivElement>(null);
    const targetView = useRef<HTMLCanvasElement>(null);
    const [targetViewSize, setTtargetViewSize] = useState<ITargetView>({
        width: 0,
        height: 0
    })

    useEffect(() => {
        const refSize = viewRef.current ? viewRef.current.getBoundingClientRect() : null

        if (refSize) {
            setTtargetViewSize({
                width: refSize.width,
                height: refSize.height
            })
        }
    }, [viewRef]);

    const generateColor = (r: number = 255, g: number = 255, b: number = 255, a: number = 255) => {
        return { r, g, b, a }
    }

    const pixelsCount =  useMemo(() => {
        const x = Math.floor((targetViewSize.width / (dotSize + dotMargin)));
        const y = Math.floor((targetViewSize.height / (dotSize + dotMargin)));

        return { x, y }
    }, [targetViewSize, dotSize, dotMargin]);

    const targetPixelsData =  useMemo(() => {
        const pixelsPosition = new Map();

        const offset = dotSize + dotMargin

        for (let x = 0; x < pixelsCount.x; x += offset) {
            for (let y = 0; y < pixelsCount.y; y += offset) {
                pixelsPosition.set({ x, y }, generateColor());
            }
        }

        return pixelsPosition
    }, [pixelsCount, dotSize, dotMargin]);

    const generateDataCanvas = (imageData: ImageData, width: number) => {
        const pixelsData = imageData.data;

        return {
            getPixelDataForCoord: (x: number, y: number) => {
                const point = y * (width * 4) + x * 4;

                return generateColor(
                    pixelsData[point],
                    pixelsData[point + 1],
                    pixelsData[point + 2],
                    pixelsData[point + 3]
                )
            }
        }
    }

    useEffect(() => {
        const ctx = targetView.current?.getContext('2d');

        if (ctx) {
            html2canvas(viewRef.current as HTMLElement)
                .then((canvas: HTMLCanvasElement) => {
                    const tCtx = canvas.getContext('2d');
                    if (tCtx) {
                        const pixelData= generateDataCanvas(tCtx.getImageData(0, 0, canvas.width, canvas.height), canvas.width);
                        targetPixelsData.forEach((color, coords) => {
                            console.log(pixelData.getPixelDataForCoord(coords.x, coords.y))
                        })
                    }
                })
        }
    }, [children, targetPixelsData])


    // const checkRender = () => {
    //
    //     const ctx = targetView.current?.getContext('2d');
    //
    //     if (ctx) {
    //         const time = Date.now()
    //         html2canvas(viewRef.current as HTMLElement)
    //             .then((canvas: HTMLCanvasElement) => {
    //                 // console.log('newCanvas: ', Date.now() - time, ' ms');
    //                 const tCtx = canvas.getContext('2d');
    //
    //
    //                 for (let x = 0; x < Number(horizontal); x++) {
    //                     for (let y = 0; y < Number(horizontal); y++) {
    //                         ctx.beginPath();
    //                         ctx.arc(x * (dotSize + dotMargin), y * (dotSize + dotMargin), dotSize / 2, 0, 2 * Math.PI);
    //                         // ctx.shadowBlur = 2;
    //                         // ctx.shadowColor = DOT_COLOR;
    //                         if (tCtx) {
    //                             const pixelData= tCtx.getImageData(x * (dotSize + dotMargin), y * (dotSize + dotMargin), 1, 1);
    //                             const isWhite = `${pixelData.data[0]}${pixelData.data[1]}${pixelData.data[2]}` === '000'
    //                             if (isWhite) {
    //                                 ctx.fillStyle = '#00ff00';
    //                                 ctx.shadowBlur = 2;
    //                                 ctx.shadowColor = '#00ff00';
    //                                 ctx.fill();
    //                             }
    //                         }
    //                         // ctx.fillStyle = '#00ff00';
    //                         // ctx.fill();
    //                     }
    //                 }
    //
    //                 console.log('Rendered: ', Date.now() - time, ' ms');
    //             })
    //     }
    // };
    //
    // checkRender()

    return (
        <>
            <canvas
                ref={targetView}
                className='targetView'
                width={targetViewSize.width}
                height={targetViewSize.height}
            >

            </canvas>
            <div ref={viewRef} className='view'>
                {children}
            </div>
        </>
    )
}

export default DottedScreen;
