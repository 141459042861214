import React from 'react';
import ReactDOM from 'react-dom/client';

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import Main from './containers/main';
import Lights from './containers/lights';
import DottedScreen from './containers/newScreen';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Router>
          <Routes>
              <Route path="/1" element={<Main />} />
              <Route path="/2" element={<Lights />} />
              <Route path="/3" element={<DottedScreen />} />
          </Routes>
      </Router>
  </React.StrictMode>
);
