import React, { useCallback } from 'react';

import './style.scss';
import Background from './components/background';
import Switcher from './components/switcher';
import Clock from './components/clock';
import Currency from './components/currency';

function Lights() {
    return (
    <div className='container'>
        <Background />
        <Switcher showTime={20}>
            <Clock />
            <Currency
                currencyNames={['RUB', 'USD', 'EUR']}
            />
        </Switcher>
    </div>
  );
}

export default Lights;
