import React, { useEffect, useState } from 'react';
import './style.scss';

const MONTH = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
]

function Clock() {
    const [time, setTime] = useState<Date>(new Date())

    useEffect(() => {
        setInterval(() => {
            setTime(new Date())
        }, 1000)
    }, [])


    return (
        <div className='wrapper'>
            <div className='clock'>
                <div
                    className='hour_hand'
                    style={{
                        transform: `rotateZ(${time.getHours() * 30}deg)`
                    }}
                />
                <div
                    className='min_hand'
                    style={{
                        transform: `rotateZ(${time.getMinutes() * 6}deg)`
                    }}
                />
                <div
                    className='sec_hand'
                    style={{
                        transform: `rotateZ(${time.getSeconds() * 6}deg)`
                    }}
                />
                <span className='twelve'>12</span>
                <span className='one'>.</span>
                <span className='two'>.</span>
                <span className='three'>3</span>
                <span className='four'>.</span>
                <span className='five'>.</span>
                <span className='six'>6</span>
                <span className='seven'>.</span>
                <span className='eight'>.</span>
                <span className='nine'>9</span>
                <span className='ten'>.</span>
                <span className='eleven'>.</span>

                <span className='date'>
                    {`${time.getUTCDate()} ${MONTH[time.getMonth()]} ${time.getUTCFullYear()} года.`}
                </span>
            </div>
        </div>
    )
}

export default Clock;
