interface IStorage {
    get: (key: string) => any;
    set: (key: string, data: any) => void
}

export const storage: IStorage = {
    get: (key: string) => {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : undefined
    },
    set: (key: string, data: any) => {
        localStorage.setItem(key, JSON.stringify(data))
    }
}