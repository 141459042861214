import React  from 'react';

import './style.scss';

function Background() {
    return (
    <div className="background">
        <div className='light x1' />
        <div className='light x2' />
        <div className='light x3' />
        <div className='light x4' />
        <div className='light x5' />
        <div className='light x6' />
        <div className='light x7' />
        <div className='light x8' />
        <div className='light x9' />
    </div>
  );
}

export default Background;
