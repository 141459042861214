import React, { useEffect, useState } from 'react';

import './style.scss';

function Clock() {
    const [time, setTime] = useState<Date>(new Date())

    useEffect(() => {
        const timeInterval = setInterval(() => {
            setTime(new Date())
        }, 1000)

        return () => clearInterval(timeInterval);
    }, [])

    const doubleDigit = (time: string) => {
        return time.length !== 2 ? `0${time}` : time
    }

    const formatTime = (time: Date) => {

        const hours = String(time.getHours());
        const minutes = String(time.getMinutes());
        const seconds = String(time.getSeconds());

        return `${doubleDigit(hours) }:${doubleDigit(minutes)}:${doubleDigit(seconds)}`
    }

    const formatDate = (date: Date) => {

        const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

        const dateDay = String(date.getDate());
        const month = String(time.getMonth() + 1);
        const year = String(time.getUTCFullYear());
        const day = time.getDay()

        return `${DAYS[day]} ${doubleDigit(dateDay) }.${doubleDigit(month)}.${year}`
    }

    return (
    <div className='dateTimeWrapper'>
        <div className='time'>
            {formatTime(time)}
        </div>
        <div className='date'>
            {formatDate(time)}
        </div>
    </div>
  );
}

export default Clock;
